























import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { config } from '@/config';

declare let window: any;
const GOERLI_TESTNET_CHAIN_ID = 5;

export default Vue.extend({
  name: 'WrongNetworkDialog',
  computed: {
    ...mapGetters('wallet', ['isInjectedProvider']),
    ...mapState('wallet', ['chainId']),
    networkName(): string {
      const toChainId = config.chainId;
      if (toChainId === GOERLI_TESTNET_CHAIN_ID) return 'Goerli Test Network';
      return 'Ethereum Mainnet';
    },
  },
  methods: {
    async switchNetworkClick(): Promise<void> {
      const switchToChainId = config.chainIdHex;
      if (window.ethereum) {
        window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: switchToChainId }],
        });
      }
    },
  },
});
